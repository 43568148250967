import { ethers } from "ethers";

export function toEth(input) {
  return ethers.utils.formatUnits(input, "ether").toString();
}

export function truncateEthAddress(address) {
  const len = address.length;
  const start = address.substr(0, 6);
  const end = address.substr(len - 4, 4);
  return `${start}....${end}`;
}

export function percentage(a, b) {
  return Number((a / b) * 100).toFixed(2);
}
