<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h3 class="title font-weight-bold mt-3">Sorry</h3>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <h6 class="headline">🥺 This page does not exist. 💔</h6>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Swap",
  data: () => ({}),
};
</script>
